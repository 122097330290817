#create-cluster-button {
  margin-top: 40px;
  margin-bottom: 10px;
}

#cancel-link {
  float: right;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-right: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ui .message {
  clear: right;
}
