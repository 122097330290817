.changepassword {
  width: 400px !important;
  margin-top: 20px;
}

.newpassword {
  margin-top: 20px !important;
}

.strength {
  width: 22ex;
  font-size: 12px !important;
  text-align: center;
}
