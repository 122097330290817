.ui.header.user_info_bar {
  margin: 0.5rem;
  margin-top: 40px;
}

.ui.menu:last-child.user-info-tab {
  margin-bottom: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
