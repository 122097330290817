.ui.menu.header {
  border-radius: 0;
}

.ui.table.compact tbody td.message {
  padding: 4rem;
  text-align: center;
}

a {
  text-decoration: underline;
}

h1.ui.header {
  font-size: 32px;
  margin-top: 10px;
}

h2.ui.header {
  font-size: 20px;
}
