.ui.button.inner-cell-button {
  margin-left: 5px;
}

.ui.button.jupyter-open-button {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.ui.button[id*="access-jupyter-button"] {
  margin-left: auto;
  margin-right: auto;
}
