.header-logo {
  margin-right: 3rem !important;
}

.ui.inverted.menu .item > a:not(.ui).header-menu {
  color: black;
}

.ui.inverted.menu {
  margin-bottom: 20px;
}
