.image-description {
  color: gray;
  background-color: #f7f7f7;
  border-radius: 5px;
  white-space: pre-wrap;
}

.image-description .header {
  color: gray;
}
